<template>
  <DashboardWidget class="col-12" :title="title">
    <!-- <PhysicalImprovementsModal
      v-if="showModal"
      @close="showModal = false"
    ></PhysicalImprovementsModal> -->
    <div id="power-zone-bar" @click="showModal = true">
      <div class="bar-row">
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
        <span></span>
      </div>

      <div class="tick-row">
        <div class="tick"></div>
        <div class="tick"></div>
        <div class="tick"></div>
        <div class="tick"></div>
        <div class="tick"></div>
        <div class="tick"></div>
        <div class="tick"></div>
      </div>

      <div class="label-row">
        <span>{{ zones[0] }}</span>
        <span>{{ zones[1] }}</span>
        <span>{{ zones[2] }}</span>
        <span>{{ zones[3] }}</span>
        <span>{{ zones[4] }}</span>
        <span>{{ zones[5] }}</span>
        <span>{{ zones[6] }}</span>
      </div>
    </div>
  </DashboardWidget>
</template>

<script>
import DashboardWidget from "@/components/DashboardWidget.vue";
// import PhysicalImprovementsModal from "@/components/PhysicalImprovementsModal.vue";

export default {
  name: "PowerZoneBar",
  components: { DashboardWidget },
  computed: {
    zones() {
      return this.$store.getters.zones;
    },
  },
  data() {
    return {
      title: "Your Zones",
      // showModal: false,
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#power-zone-bar {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 50px 0 30px 0;

  .bar-row {
    display: flex;
    justify-content: center;

    span {
      width: 13%;
      height: 20px;
    }

    :nth-child(1) {
      background-color: #60aed2;
      border-radius: 20px 0 0 20px;
      border-right: 1px white solid;

      // &:hover {
      //   cursor: pointer;
      //   opacity: 0.5;
      // }
    }
    :nth-child(2) {
      background-color: #51c7a9;
      border-left: 1px white solid;
      border-right: 1px white solid;
    }
    :nth-child(3) {
      background-color: #b7ca58;
      border-left: 1px white solid;
      border-right: 1px white solid;
    }
    :nth-child(4) {
      background-color: #dcb23c;
      border-left: 1px white solid;
      border-right: 1px white solid;
    }
    :nth-child(5) {
      background-color: #d79a34;
      border-left: 1px white solid;
      border-right: 1px white solid;
    }
    :nth-child(6) {
      background-color: #da7214;
      border-left: 1px white solid;
      border-right: 1px white solid;
    }
    :nth-child(7) {
      background-color: #df4155;
      border-radius: 0 20px 20px 0;
      border-left: 1px white solid;
    }
  }

  .tick-row {
    display: flex;
    justify-content: center;

    .tick {
      width: 13%;
      height: 10px;
      border-left: 1px black solid;
      border-top: none;
      border-right: none;
      border-bottom: none;
    }
  }

  .label-row {
    display: flex;
    justify-content: center;

    span {
      width: 13%;
      text-align: left;
    }
  }
}
</style>
