<template>
  <DashboardWidget class="col-12 col-md-6" :title="title">
    <span class="number">{{ totalRideTime.number }}</span>
  </DashboardWidget>
</template>

<script>
import _ from "lodash";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "TotalRideTime",
  components: { DashboardWidget },
  props: { value: Number },
  computed: {
    totalRideTime() {
      var num = this.value;
      var days = _.round(num / (24 * 3600), 2);

      num = num % (24 * 3600);
      var hours = _.round(num / 3600, 2);

      num %= 3600;
      var minutes = _.round(num / 60, 2);

      if (days >= 1) {
        return {
          number: days,
          unit: "days",
        };
      } else if (hours >= 1) {
        return {
          number: hours,
          unit: "hrs",
        };
      } else {
        return {
          number: minutes,
          unit: "min",
        };
      }
    },
    title() {
      return "Total Ride Time (" + this.totalRideTime.unit + ")";
    },
  },
  data() {
    return {
      // title: "Total Ride Time (" + this.totalRideTime[1] + ")",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
span {
  text-align: center;
  width: 100%;
}

.number {
  font-weight: 900;
  font-size: 70px;
}

.unit {
  font-size: 18px;
}
</style>
