<template>
    <div class="widget-box">
        <h1>{{ title }}</h1>
        <div class="content-area">
            <slot></slot>
        </div>
    </div>
</template>

<script>
export default {
    name: "DashboardWidget",
    props: { title: String },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.widget-box {
    margin-top: 10px;
    
    h1 {
        margin-bottom: 0;
        width: 100%;
        text-align: center;
        font-weight: 200;
        background: #f4f7f9;
        // height: 75px;
        // line-height: 75px;
        padding: 10px 0 10px 0;
        border-top-left-radius: 5px;
        border-top-right-radius: 5px;
        font-size: 2rem;
    }

    .content-area {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 175px;
        text-align: center;
        background: #fff;
        border-bottom-left-radius: 5px;
        border-bottom-right-radius: 5px;
    }
}
</style>