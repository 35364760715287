<template>
  <DashboardWidget class="col-12 col-md-6" :title="title">
    <span>{{ value }}</span>
  </DashboardWidget>
</template>

<script>
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "PeakOutput",
  components: { DashboardWidget },
  props: { value: Number },
  data() {
    return {
      title: "Peak Output (watts)",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
span {
  font-weight: 900;
  font-size: 70px;
  text-align: center;
  width: 100%;
}
</style>
