<template>
    <b-container class="pb-4">
        <b-row>
            <div class="spinner-wrapper" v-if="showSpinner">
                <rotate-loader :loading="true"> </rotate-loader>
            </div>

            <LastFtpTest></LastFtpTest>

            <PowerToWeightRatioGauge :goal="originalGoal"> </PowerToWeightRatioGauge>

            <DashboardWidget class="col-12" :title="title">
                <div class="form-wrapper">
                    <b-form @submit.prevent="saveGoal" @reset.prevent="resetGoal">
                        <b-form-group id="goal-weight-group" label="Weight (lbs):" label-for="goal-weight">
                            <b-form-spinbutton
                                id="goal-weight"
                                v-model="modifiedGoal.weight"
                                min="1"
                                max="1000"
                                size="lg"
                                :state="goalWeightInputState"
                            ></b-form-spinbutton>
                        </b-form-group>

                        <b-form-group
                            id="goal-output-group"
                            label="Avg Output (Watts):"
                            label-for="goal-output"
                            :description="'Corresponding FTP: ' + avgTestOutput"
                        >
                            <b-form-spinbutton
                                id="goal-output"
                                v-model="modifiedGoal.output"
                                min="1"
                                max="1000"
                                size="lg"
                                :state="goalOutputInputState"
                            ></b-form-spinbutton>
                        </b-form-group>

                        <b-form-group id="goal-date-group" label="Goal End Date:" label-for="goal-date" description="Perfect for planning your next FTP test">
                            <b-form-datepicker
                                id="goal-date"
                                v-model="modifiedGoal.date"
                                :min="min"
                                :date-format-options="{
                                    year: 'numeric',
                                    month: 'numeric',
                                    day: 'numeric',
                                }"
                                locale="en"
                                menu-class="w-100"
                                calendar-width="100%"
                                size="lg"
                                :state="goalDateInputState"
                            ></b-form-datepicker>
                        </b-form-group>

                        <div class="button-wrapper">
                            <b-button type="reset" :disabled="!goalModified" variant="outline-primary">Reset </b-button>
                            <b-button type="submit" :disabled="!goalModified" variant="primary">Save </b-button>
                        </div>
                    </b-form>
                </div>
            </DashboardWidget>
        </b-row>
    </b-container>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import DashboardWidget from "@/components/DashboardWidget.vue";
import LastFtpTest from "@/components/LastFtpTest.vue";
import PowerToWeightRatioGauge from "@/components/PowerToWeightRatioGauge.vue";
import RotateLoader from "vue-spinner/src/RotateLoader.vue";

export default {
    name: "FTP",
    components: {
        DashboardWidget,
        LastFtpTest,
        PowerToWeightRatioGauge,
        RotateLoader,
    },
    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const minDate = new Date(today);

        return {
            userId: localStorage.getItem("userId"),
            title: "Goal",
            min: minDate,
            editingGoal: false,
            savingGoal: false,

            goalWeightInputState: null,
            goalOutputInputState: null,
            goalDateInputState: null,
            originalGoal: {
                weight: null,
                output: null,
                date: null,
            },
            modifiedGoal: {
                weight: null,
                output: null,
                date: null,
            },
        };
    },
    computed: {
        userData() {
            return this.$store.state.userData;
        },
        avgTestOutput() {
            return _.round(this.modifiedGoal.output * 0.95);
        },
        rideData() {
            return this.$store.state.rideData;
        },
        showSpinner() {
            return (
                // Show if saving data
                this.savingGoal ||
                // Or if we are retrieving data for the first time
                (this.modifiedGoal.weight === null && this.originalGoal.weight === null)
            );
        },
        goalModified() {
            return !_.isEqual(this.modifiedGoal, this.originalGoal);
        },
    },
    watch: {
        "modifiedGoal.weight": function () {
            //Clear form state on change
            this.goalWeightInputState = null;
        },
        "modifiedGoal.output": function () {
            //Clear form state on change
            this.goalOutputInputState = null;
        },
        "modifiedGoal.date": function () {
            //Clear form state on change
            this.goalDateInputState = null;
        },
    },
    methods: {
        saveGoal() {
            if (!this.modifiedGoal.weight || !this.modifiedGoal.output || !this.modifiedGoal.date) {
                // Set error styling states
                this.goalWeightInputState = this.modifiedGoal.weight ? true : false;
                this.goalOutputInputState = this.modifiedGoal.output ? true : false;
                this.goalDateInputState = this.modifiedGoal.date ? true : false;

                return;
            }

            // Clear error styling states
            this.goalWeightInputState = null;
            this.goalOutputInputState = null;
            this.goalDateInputState = null;

            this.savingGoal = true;
            this.editingGoal = false;

            axios
                .put("/api/goals/" + this.userId, this.modifiedGoal)
                .then(() => {
                    this.originalGoal = _.clone(this.modifiedGoal);
                    this.savingGoal = false;
                })
                .catch((error) => {
                    this.savingSGoal = false;
                    this.editingGoal = true;

                    console.log(error);
                });
        },
        resetGoal() {
            this.modifiedGoal = _.clone(this.originalGoal);

            //Clear form state on change
            this.goalWeightInputState = null;
            this.goalOutputInputState = null;
            this.goalDateInputState = null;
        },
    },
    created() {
        window.scrollTo(0, 0);

        axios
            .get("/api/goals/" + this.userId)
            .then((response) => {
                if (response.data) {
                    this.originalGoal = response.data;
                    this.modifiedGoal = _.clone(this.originalGoal);
                } else {
                    this.originalGoal = {
                        weight: this.$store.state.userData.weight,
                        output: _.round(this.$store.state.userData.cycling_workout_ftp / 0.95),
                        date: null,
                    };
                    this.modifiedGoal = _.clone(this.originalGoal);
                }
            })
            .catch((error) => {
                console.log(error);
            });
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.spinner-wrapper {
    position: -webkit-sticky; /* for safari */
    position: sticky;
    z-index: 1;
    top: 61px;
    bottom: 0;
    padding-top: 40vh;
    background: white;
    opacity: 0.75;
    width: 100%;
    height: 100vh;
}

.v-spinner {
    text-align: center;
}

.spinner-text {
    text-align: center;
    margin: auto;
    margin-top: 25px;
}

.form-wrapper {
    text-align: left;
    margin-top: 20px;
    padding: 30px;
}

.button-wrapper {
    margin-top: 20px;
    text-align: right;

    button {
        margin-left: 10px;
    }
}
</style>
