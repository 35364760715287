<template>
  <b-navbar toggleable="md" type="dark" variant="dark" sticky>
    <b-navbar-brand href="#">
      <img class="navbar-icon" src="../assets/the_power_zone_logo.svg" alt />
    </b-navbar-brand>

    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <b-navbar-nav>
        <b-nav-item
          to="/"
          v-bind:active="$route.name === 'Stats'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >Stats</b-nav-item
        >
        <b-nav-item
          to="/zones"
          v-bind:active="$route.name === 'Zones'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >Zones</b-nav-item
        >
        <b-nav-item
          to="/ftp"
          v-bind:active="$route.name === 'FTP'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >FTP</b-nav-item
        >
        <b-nav-item
          to="/rides"
          v-bind:active="$route.name === 'Rides'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >Rides</b-nav-item
        >
        <b-nav-item
          to="/challenges"
          v-bind:active="$route.name === 'Challenges'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >Challenges</b-nav-item
        >
        <b-nav-item
          to="/about"
          v-bind:active="$route.name === 'About'"
          v-bind:disabled="$route.name === 'GettingStarted'"
          >About</b-nav-item
        >
      </b-navbar-nav>

      <!-- Right aligned nav items -->
      <b-navbar-nav class="ml-auto">
        <button class="btn btn-outline-info" v-on:click="logout">
          Logout
        </button>
      </b-navbar-nav>
    </b-collapse>
  </b-navbar>
</template>

<script>
export default {
  name: "TopNavBar",
  methods: {
    logout: function() {
      this.$router.push("/login");
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.navbar-icon {
  height: 50px;
}

.navbar {
  font-weight: 200;
}
</style>
