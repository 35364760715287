import _ from "lodash";
import axios from "axios";
import Vue from "vue";
import Vuex from "vuex";

Vue.use(Vuex);

function _rideDefunct(savedRide) {
  var isDefunct = false;

  if (savedRide.id === null || savedRide.id === undefined) {
    isDefunct = true;
  }
  if (savedRide.is_ftp === null || savedRide.is_ftp === undefined) {
    isDefunct = true;
  }
  if (savedRide.duration === null || savedRide.duration === undefined) {
    isDefunct = true;
  }
  if (savedRide.distance === null || savedRide.distance === undefined) {
    isDefunct = true;
  }
  if (savedRide.calories === null || savedRide.calories === undefined) {
    isDefunct = true;
  }
  if (savedRide.created_at === null || savedRide.created_at === undefined) {
    isDefunct = true;
  }
  if (savedRide.total_output === null || savedRide.total_output === undefined) {
    isDefunct = true;
  }
  if (savedRide.avg_output === null || savedRide.avg_output === undefined) {
    isDefunct = true;
  }
  if (savedRide.max_output === null || savedRide.max_output === undefined) {
    isDefunct = true;
  }
  if (savedRide.title === null || savedRide.title === undefined) {
    isDefunct = true;
  }
  if (savedRide.note === null || savedRide.note === undefined) {
    isDefunct = true;
  }
  if (savedRide.rating === undefined) {
    // Could be null if not rated
    isDefunct = true;
  }
  if (savedRide.status !== "COMPLETE") {
    // Might have loaded the ride part way through. Reload and hope
    // they finished it. Worst case we'll constantly requery rides
    // that are saved but not completed.
    isDefunct = true;
  }

  return isDefunct;
}

export default new Vuex.Store({
  state: {
    refreshPending: false,
    userData: null,
    rideData: {},
    cacheData: {},
  },
  getters: {
    totals: (state) => {
      return _.reduce(
        state.rideData,
        function(result, value) {
          result.duration += value.duration;
          result.distance += value.distance;
          result.calories += value.calories;
          return result;
        },
        { duration: 0, distance: 0, calories: 0 }
      );
    },
    ftpTests: (state) => {
      return _.filter(state.rideData, "is_ftp");
    },
    peakOutput: (state) => {
      var outputs = _.map(state.rideData, "max_output");
      return _.max(outputs);
    },
    zones: (state) => {
      // Percentages from: https://blog.onepeloton.com/power-zone-training/
      return [
        0,
        Math.round(0.555 * state.userData.cycling_workout_ftp),
        Math.round(0.755 * state.userData.cycling_workout_ftp),
        Math.round(0.905 * state.userData.cycling_workout_ftp),
        Math.round(1.055 * state.userData.cycling_workout_ftp),
        Math.round(1.205 * state.userData.cycling_workout_ftp),
        Math.round(1.505 * state.userData.cycling_workout_ftp),
      ];
    },
    freshestRideCount: (state) => {
      return _.find(state.userData.workout_counts, {
        name: "Cycling",
      }).count;
    },
    ridesDoneLoading: (state, getters) => {
      return _.keys(state.rideData).length === getters.freshestRideCount;
    },
  },
  mutations: {
    refreshPending(state, isPending) {
      state.refreshPending = isPending;
    },
    userData(state, userData) {
      state.userData = userData;
    },
    addCachedRide(state, ride) {
      state.cacheData[ride.id] = ride;
    },
    addContributingRide(state, ride) {
      // Explicit vue manipulation required to trigger evaluation.
      // See: https://stackoverflow.com/questions/40860592/vuex-getter-not-updating
      //
      // Used a simple array w/ push originally but that wasn't idempotent
      // and created dups
      Vue.set(state.rideData, ride.id, ride);
    },
    saveRideNote(state, noteContext) {
      state.rideData[noteContext.rideId].note = noteContext.value;
    },
    saveRideRating(state, ratingContext) {
      state.rideData[ratingContext.rideId].rating = ratingContext.value;
    },
  },
  actions: {
    // getOverview() {
    //   axios
    //     .get("/api/user/" + this.user_id + "/overview", {
    //       params: {
    //         version: "1",
    //       },
    //       headers: {
    //         peloton_session_id: this.session_id,
    //       },
    //     })
    //     .then((response) => {
    //       commit("userData", response.data.user);
    //       dispatch("getrideData");
    //     })
    //     .catch((error) => {
    //       commit("refreshPending", false);
    //       console.log(error);
    //     });
    // },
    refreshRideData({ state, commit, dispatch }) {
      var sessionId = localStorage.getItem("sessionId");

      if (!state.refreshPending) {
        commit("refreshPending", true);

        axios
          .get("/auth/check_session", {
            headers: {
              peloton_session_id: sessionId,
            },
          })
          .then((response) => {
            commit("userData", response.data.user);
            dispatch("getrideData");
          })
          .catch((error) => {
            commit("refreshPending", false);
            console.log(error);
          });
      } else {
        console.log("refresh already pending");
      }
    },
    getrideData({ commit, dispatch }) {
      var userId = localStorage.getItem("userId");

      axios
        .get("/api/cache/" + userId + "/ride-data-manifest")
        .then((response) => {
          if (response.data) {
            _.each(response.data.manifest, async function(chunkId) {
              await axios
                .get("/api/cache/" + userId + "/ride-data/" + chunkId)
                .then((response) => {
                  if (response.data) {
                    _.each(response.data.rideData, function(ride) {
                      commit("addCachedRide", ride);
                    });
                  }
                })
                .catch((error) => {
                  commit("refreshPending", false);
                  console.log(error);
                });
            });
          }

          dispatch("getRideHistory");
        })
        .catch((error) => {
          commit("refreshPending", false);
          console.log(error);
        });
    },
    async getRideHistory({ state, commit, dispatch }) {
      var sessionId = localStorage.getItem("sessionId");
      var userId = localStorage.getItem("userId");
      var morePagesToGet = true;
      var pagesRequested = 0;
      const workoutsPerPage = 100;

      while (morePagesToGet) {
        await axios
          .get("/api/user/" + userId + "/workouts", {
            params: {
              joins: "peloton.ride",
              limit: workoutsPerPage,
              page: pagesRequested,
            },
            headers: {
              peloton_session_id: sessionId,
            },
          })
          .then((response) => {
            pagesRequested++;
            if (response.data.count < workoutsPerPage) {
              morePagesToGet = false;
            }

            var cyclingWorkoutsRetrieved = _.filter(response.data.data, {
              fitness_discipline: "cycling",
            });

            // Get details for uncached/new rides
            _.each(cyclingWorkoutsRetrieved, (ride) => {
              var cachedVersionOfRide = state.cacheData[ride.id];

              if (!cachedVersionOfRide) {
                dispatch("getRideDetails", ride);
              } else if (_rideDefunct(cachedVersionOfRide)) {
                // If the ride is already cached, but there are new fields being tracked,
                // then we need to clear what we have stored and start over. We're about to
                // delete this ride data so pass through any metadata that won't be pulled
                // from Peloton APIs
                ride.note = state.cacheData[ride.id].note
                  ? state.cacheData[ride.id].note
                  : "";

                ride.rating = state.cacheData[ride.id].rating
                  ? state.cacheData[ride.id].rating
                  : null;

                dispatch("getRideDetails", ride);
              } else {
                commit("addContributingRide", cachedVersionOfRide);
              }
            });
          })
          .catch((error) => {
            morePagesToGet = false; //bail out
            commit("refreshPending", false);
            console.log(error);
          });
      }
    },
    getRideDetails({ commit, dispatch, getters }, ride) {
      var sessionId = localStorage.getItem("sessionId");

      axios
        .get("/api/workout/" + ride.id + "/performance_graph", {
          headers: {
            peloton_session_id: sessionId,
          },
        })
        .then((response) => {
          var maxOutput = _.find(response.data.metrics, {
            display_name: "Output",
          }).max_value;

          var avgOutput = _.find(response.data.average_summaries, {
            display_unit: "watts",
          }).value;

          var totalOutput = _.find(response.data.summaries, {
            display_unit: "kj",
          }).value;

          var distance = _.find(response.data.summaries, {
            display_unit: "mi",
          }).value;

          var calories = _.find(response.data.summaries, {
            display_unit: "kcal",
          }).value;

          // If you add any new fields here be sure to also add the check in _rideDefunct() above
          commit("addContributingRide", {
            id: ride.id,
            is_ftp:
              ride.peloton &&
              ride.peloton.ride.title.toLowerCase() === "20 min ftp test ride"
                ? true
                : false,
            duration: response.data.duration,
            distance: distance,
            calories: calories,
            created_at: ride.created_at,
            total_output: totalOutput,
            avg_output: avgOutput,
            max_output: maxOutput,
            title:
              ride.peloton && ride.peloton.ride.title
                ? ride.peloton.ride.title.toLowerCase()
                : "untitled",
            note: ride.note,
            rating: ride.rating,
            status: ride.status,
          });

          if (getters.ridesDoneLoading) {
            dispatch("saveAllRideData");
          }
        })
        .catch((error) => {
          commit("refreshPending", false);
          console.log(error);
        });
    },
    saveAllRideData({ state, commit }) {
      var userId = localStorage.getItem("userId");

      var chunks = _.chunk(
        _.sortBy(_.values(state.rideData), "created_at"),
        100
      );

      var chunkError = false;
      var manifest = [];

      _.each(chunks, async function(chunk) {
        var chunkId = chunk[0].id;
        manifest.push(chunkId);

        await axios
          .put("/api/cache/" + userId + "/ride-data/" + chunkId, {
            rideData: chunk,
          })
          .catch((error) => {
            chunkError = true;
            commit("refreshPending", false);
            console.log(error);
          });
      });

      if (!chunkError) {
        axios
          .put("/api/cache/" + userId + "/ride-data-manifest", {
            manifest: manifest,
          })
          .catch((error) => {
            commit("refreshPending", false);
            console.log(error);
          });
      }

      commit("refreshPending", false);
    },
  },
  modules: {},
});
