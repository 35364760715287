<template>
    <b-container class="pb-4">
        <b-row>
            <PowerZoneBar></PowerZoneBar>
            <PhysicalImprovementsTable></PhysicalImprovementsTable>
        </b-row>
    </b-container>
</template>

<script>
import PowerZoneBar from "@/components/PowerZoneBar";
import PhysicalImprovementsTable from "@/components/PhysicalImprovementsTable";

export default {
    name: "Zones",
    components: {
        PowerZoneBar,
        PhysicalImprovementsTable,
    },
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
