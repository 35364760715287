<template>
    <!-- if pagination is desired this helped:
 https://medium.com/@nickpgott/how-to-use-vue-bootstrap-to-paginate-a-list-of-items-3b8e67093c07 -->
    <b-container v-bind:class="{ 'pb-3': padBottom }">
        <b-row class="sticky-controls pt-3 pb-3">
            <b-col>
                <b-form-select v-model="filterSelected" :options="filterOptions"></b-form-select>
            </b-col>

            <b-col>
                <b-form-select v-model="sortSelected" :options="sortOptions"></b-form-select>
            </b-col>
        </b-row>

        <b-row>
            <Ride id="rideList" v-for="ride in ridesForCurrentPage" :key="ride.id" v-bind:ride="ride"></Ride>
        </b-row>

        <b-row class="sticky-pagination" v-if="totalRides > perPage">
            <b-col>
                <b-pagination
                    class="mt-3"
                    v-model="currentPage"
                    :total-rows="totalRides"
                    :per-page="perPage"
                    aria-controls="rideList"
                    align="center"
                ></b-pagination>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import _ from "lodash";
import Ride from "@/components/Ride";

export default {
    name: "Zones",
    components: {
        Ride,
    },
    watch: {
        filterSelected() {
            window.scrollTo(0, 0);
            this.filterOptions[0].text = this.filterSelected ? "Remove Filter" : "Filter";
        },
        sortSelected() {
            window.scrollTo(0, 0);
            this.sortOptions[0].text = this.sortSelected ? "Remove Sort" : "Sort";
        },
    },
    data() {
        return {
            currentPage: 1,
            perPage: 20,
            filterSelected: null,
            filterOptions: [
                { value: null, text: "Filter" },
                { value: "is_ftp", text: "FTP Tests" },
                { value: ["duration", 5 * 60], text: "5 min" },
                { value: ["duration", 10 * 60], text: "10 min" },
                { value: ["duration", 15 * 60], text: "15 min" },
                { value: ["duration", 20 * 60], text: "20 min" },
                { value: ["duration", 30 * 60], text: "30 min" },
                { value: ["duration", 45 * 60], text: "45 min" },
                { value: ["duration", 60 * 60], text: "60 min" },
                { value: ["duration", 75 * 60], text: "75 min" },
                { value: ["duration", 90 * 60], text: "90 min" },
            ],
            sortSelected: null,
            sortOptions: [
                { value: null, text: "Sort" },
                {
                    value: {
                        iteratees: [
                            (ride) => {
                                return ride.rating || "";
                            },
                            "created_at",
                        ],
                        orders: ["desc", "desc"],
                    },
                    text: "Feel Rating",
                },
                { value: { iteratees: "created_at", orders: "asc" }, text: "Oldest" },
                { value: { iteratees: "created_at", orders: "desc" }, text: "Newest" },
                { value: { iteratees: ["total_output", "created_at"], orders: ["desc", "desc"] }, text: "Total Output" },
                { value: { iteratees: ["avg_output", "created_at"], orders: ["desc", "desc"] }, text: "Avg Output" },
            ],
        };
    },
    computed: {
        filteredAndSortedRides() {
            var filteredAndSortedRides = this.$store.state.rideData;

            if (this.filterSelected) {
                // Makes a copy
                filteredAndSortedRides = _.filter(filteredAndSortedRides, this.filterSelected);
            }

            if (this.sortSelected) {
                // Makes a copy
                filteredAndSortedRides = _.orderBy(filteredAndSortedRides, this.sortSelected.iteratees, this.sortSelected.orders);
            } else {
                // Makes a copy, default to newest on top
                filteredAndSortedRides = _.orderBy(filteredAndSortedRides, "created_at", "desc");
            }

            return filteredAndSortedRides;
        },
        ridesForCurrentPage() {
            return _.values(this.filteredAndSortedRides).slice((this.currentPage - 1) * this.perPage, this.currentPage * this.perPage);
        },
        totalRides() {
            return _.keys(this.filteredAndSortedRides).length;
        },
        padBottom() {
            // If the pager is gone (due to filter/sort rides < page size),
            // pad the container, otherwise the pager has spacing
            return this.totalRides <= this.perPage;
        },
    },
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.sticky-controls {
    position: -webkit-sticky;
    position: sticky;
    top: 76px;
    z-index: 100;
    background: #e4e7eb;
}

.sticky-pagination {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
    background: #e4e7eb;
}
</style>
