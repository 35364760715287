import Vue from 'vue'
import App from './App.vue'
import HighchartsVue from 'highcharts-vue'
import Highcharts from 'highcharts'
import StockInit from "highcharts/modules/stock";
import BulletInit from 'highcharts/modules/bullet'
import router from '@/router/router';
import store from '@/store/store';

import { BootstrapVue, BootstrapVueIcons } from 'bootstrap-vue';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';

Vue.config.productionTip = false;

BulletInit(Highcharts);
StockInit(Highcharts);

Vue.use(BootstrapVue);
Vue.use(BootstrapVueIcons);
Vue.use(HighchartsVue);

Vue.filter('shortenNumber', function (value, places) {
  if (!value) return ''

  var val = parseInt(value);
  if (val === 0) {
    return val;
  }
  places = places || 0;
  var absVal = Math.abs(Number(val));
  var absPlaces = Math.log10(absVal);
  var fixedPlaces = places - (Math.floor(absPlaces) % 3);
  if (fixedPlaces < 0) {
    fixedPlaces = 0;
  }
  var suffixes = ['', 'K', 'M', 'B', 'T'];
  var suffixIdx = Math.floor(absPlaces / 3);
  val = val / Math.pow(10, suffixIdx * 3);
  return val.toFixed(fixedPlaces) + suffixes[suffixIdx];
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app');
