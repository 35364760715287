<template>
  <DashboardWidget class="col-12" :title="title">
    <div class="table-scroll-wrapper">
      <table class="table table-bordered table-hover table-condensed">
        <colgroup>
          <col class="benefit" />
          <col class="zone-1" />
          <col class="zone-2" />
          <col class="zone-3" />
          <col class="zone-4" />
          <col class="zone-5" />
          <col class="zone-6" />
          <col class="zone-7" />
        </colgroup>
        <thead>
          <tr>
            <th title="Field #1">Benefit</th>
            <th title="Field #2">Zone 1</th>
            <th title="Field #3">Zone 2</th>
            <th title="Field #4">Zone 3</th>
            <th title="Field #5">Zone 4</th>
            <th title="Field #6">Zone 5</th>
            <th title="Field #7">Zone 6</th>
            <th title="Field #8">Zone 7</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Increased plasma volume</td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased muscle mitochondrial enzymes</td>
            <td></td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased lactate threshold</td>
            <td></td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased muscle glycogen storage</td>
            <td></td>
            <td>✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Hypertrophy of slow twitch muscle fibers</td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased muscle capillarization</td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>
              Interconversion of fast twitch muscle fibers (type llb -&gt; type
              lla)
            </td>
            <td></td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased stroke volume/maximal cardiac output</td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased VO2 Max</td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
            <td>✓✓✓</td>
            <td>✓✓✓✓</td>
            <td>✓</td>
            <td></td>
          </tr>
          <tr>
            <td>Increased Muscle high energy phosphate (ATP/PCr) stores</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
          </tr>
          <tr>
            <td>Increased anaerobic capacity (“lactate tolerance”)</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✓</td>
            <td>✓✓✓</td>
            <td>✓</td>
          </tr>
          <tr>
            <td>Hypertrophy of fast twitch muscle fibers</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✓</td>
            <td>✓✓</td>
          </tr>
          <tr>
            <td>Increased neuromuscular power</td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td></td>
            <td>✓</td>
            <td>✓✓✓</td>
          </tr>
        </tbody>
      </table>
    </div>
  </DashboardWidget>
</template>

<script>
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "PhysicalImprovementsTable",
  data() {
    return {
      title: "Zone Benefits",
    };
  },
  components: { DashboardWidget },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.table-scroll-wrapper {
  overflow-x: auto;
}

table {
  margin-bottom: 0;
}

.zone-1 {
  background-color: #60aed2;
}
.zone-2 {
  background-color: #51c7a9;
}
.zone-3 {
  background-color: #b7ca58;
}
.zone-4 {
  background-color: #dcb23c;
}
.zone-5 {
  background-color: #d79a34;
}
.zone-6 {
  background-color: #da7214;
}
.zone-7 {
  background-color: #df4155;
}

thead tr {
  text-align: center;
}

th {
  min-width: 80px;
}
</style>
