<template>
    <b-container fluid>
        <div class="row">
            <div class="col-md-9 col-xl-8 col-12 pb-md-3 pl-md-5">
                <h1 id="getting-started" class="bv-no-focus-ring" tabindex="-1">Welcome!</h1>
                <p>
                    Thanks for checking out The Power Zone. Unfortunately, we are unable to retrieve your FTP. Please make sure you have your Power Zones
                    enabled on your bike and then check back.
                    <br />
                    <br />For more information on enabling Power Zone training and getting your first FTP score, please refer to
                    <a href="https://blog.onepeloton.com/power-zone-training/">The official Peloton guidance</a>.
                    <br />
                    <br />
                    <br />-together we go far
                </p>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "GettingStarted",
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    padding-top: 20px;
}
</style>
