<template>
    <!-- https://getbootstrap.com/docs/4.0/examples/sign-in/ -->
    <b-container>
        <img src="../assets/the_power_zone_banner.svg" alt />
        <form class="form-signin" @submit.prevent="handleLogin">
            <h1 class="h3 mb-3 font-weight-normal">Please sign in</h1>
            <label for="inputUsernameOrEmail" class="sr-only">Email address</label>
            <input v-model="username_or_email" id="inputUsernameOrEmail" class="form-control" placeholder="Username or Email" required autofocus />
            <label for="inputPassword" class="sr-only">Password</label>
            <input v-model="password" type="password" id="inputPassword" class="form-control" placeholder="Password" required />
            <button class="btn btn-lg btn-primary btn-block" type="submit">Sign in</button>
        </form>
    </b-container>
</template>

<script>
import axios from "axios";

export default {
    name: "Login",
    data() {
        return {
            username_or_email: "",
            password: "",
        };
    },
    methods: {
        async handleLogin() {
            axios
                .post("/auth/login", {
                    username_or_email: this.username_or_email,
                    password: this.password,
                })
                .then((response) => {
                    localStorage.setItem("sessionId", response.data.session_id);
                    localStorage.setItem("userId", response.data.user_id);

                    axios
                        .get("/api/me", {
                            headers: {
                                peloton_session_id: response.data.session_id,
                            },
                        })
                        .then((response) => {
                            if (!response.data.cycling_workout_ftp) {
                                this.$router.push("getting-started");
                            } else {
                                this.$router.push("/");
                            }
                        });
                });
        },
    },
    created() {
        window.scrollTo(0, 0);

        localStorage.clear();
        this.$store.commit("userData", null);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.container {
    text-align: center;
}

img {
    width: 50%;
    min-width: 290px;
    margin: 30px 0 30px 0;
}
.form-signin {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: 0 auto;
}
.form-signin .checkbox {
    font-weight: 400;
}
.form-signin .form-control {
    position: relative;
    box-sizing: border-box;
    height: auto;
    padding: 10px;
    font-size: 16px;
}
.form-signin .form-control:focus {
    z-index: 2;
}
.form-signin input[type="email"] {
    margin-bottom: -1px;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
}
.form-signin input[type="password"] {
    margin-bottom: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
}
</style>
