<template>
  <DashboardWidget class="col-12 col-md-6" :title="title">
    <span>{{ totalDistance }}</span>
  </DashboardWidget>
</template>

<script>
import _ from "lodash";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "TotalDistance",
  components: { DashboardWidget },
  props: { value: Number },
  computed: {
    totalDistance() {
      return _.round(this.value, 2);
    },
  },
  data() {
    return {
      title: "Total Distance (mi)",
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
span {
  font-weight: 900;
  font-size: 70px;
  text-align: center;
  width: 100%;
}
</style>
