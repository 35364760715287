<template>
    <div v-if="userData">
        <div class="spinner-wrapper" v-if="!ridesDoneLoading">
            <rotate-loader :loading="true"/>
            <div class="spinner-text">Tallying {{ talliedRides }} of {{ freshestRideCount }} rides...</div>
        </div>

        <b-container v-if="ridesDoneLoading" class="pb-4">
            <b-row>
                <TotalRideCount :value="freshestRideCount"></TotalRideCount>
                <TotalDistance :value="totals.distance"></TotalDistance>
                <TotalCalories :value="totals.calories | shortenNumber"></TotalCalories>
                <TotalRideTime :value="totals.duration"></TotalRideTime>
                <TotalFtpTests :value="ftpTests.length"></TotalFtpTests>
                <PeakOutput :value="peakOutput"> </PeakOutput>
                <OutputOverTimeGraph></OutputOverTimeGraph>
            </b-row>
        </b-container>
    </div>
</template>

<script>
import _ from "lodash";
import RotateLoader from "vue-spinner/src/RotateLoader.vue";

import OutputOverTimeGraph from "@/components/OutputOverTimeGraph.vue";
import PeakOutput from "@/components/PeakOutput.vue";
import TotalRideCount from "@/components/TotalRideCount.vue";
import TotalDistance from "@/components/TotalDistance.vue";
import TotalCalories from "@/components/TotalCalories.vue";
import TotalRideTime from "@/components/TotalRideTime.vue";
import TotalFtpTests from "@/components/TotalFtpTests.vue";

export default {
    name: "Stats",
    components: {
        RotateLoader,
        OutputOverTimeGraph,
        PeakOutput,
        TotalRideCount,
        TotalDistance,
        TotalCalories,
        TotalRideTime,
        TotalFtpTests,
    },
    computed: {
        userData() {
            return this.$store.state.userData;
        },
        rideData() {
            return this.$store.state.rideData;
        },
        talliedRides() {
            return _.keys(this.$store.state.rideData).length;
        },
        totals() {
            return this.$store.getters.totals;
        },
        ftpTests() {
            return this.$store.getters.ftpTests;
        },
        peakOutput() {
            return this.$store.getters.peakOutput;
        },
        ridesDoneLoading() {
            return this.$store.getters.ridesDoneLoading;
        },
        freshestRideCount() {
            return this.$store.getters.freshestRideCount;
        },
    },
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    text-align: center;
}
.spinner-wrapper {
    position: -webkit-sticky; /* for safari */
    position: sticky;
    z-index: 1;
    top: 61px;
    bottom: 0;
    padding-top: 40vh;
    background: white;
    opacity: 0.75;
    width: 100%;
    height: 100vh;
}
.v-spinner {
    color: red;
    text-align: center;
}
.spinner-text {
    text-align: center;
    margin: auto;
    margin-top: 25px;
}
</style>
