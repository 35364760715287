<template>
    <b-container>
        <div class="row">
            <div class="col-12">
                <h1>Welcome!</h1>
                <p>
                    Thanks for checking out my page. I'm an avid Peloton rider and Power Zone fanatic that just wanted a better way to set goals and validate
                    progress. I have a million things I want to implement, but I'm doing this in my spare time so who knows how long it'll take.
                    <br />
                    <br />
                    This is just a fun project for me and I make no guarantees regarding data safety or privacy. I'm not selling your info or hacking your
                    accounts, but I don't want you to sue me if someone else finds a way to do that. If that makes you nervous, don't use this site.
                    <br />
                    <br />
                    <br />
                    -together we go far
                </p>
            </div>
        </div>
    </b-container>
</template>

<script>
export default {
    name: "About",
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
    padding-top: 20px;
}
</style>
