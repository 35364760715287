<template>
    <DashboardWidget class="col-12" :title="title">
        <b-container class="py-2">
            <b-row>
                <b-col>
                    <b-button
                        class="float-right"
                        variant="outline-primary"
                        v-on:click="calculateIntesityFactor"
                        v-b-tooltip.hover
                        title="Challenge a friend to this ride"
                    >
                        <svg width="1em" height="1em" viewBox="0 0 16 16" class="bi bi-trophy" fill="currentColor" xmlns="http://www.w3.org/2000/svg">
                            <path
                                fill-rule="evenodd"
                                d="M2.5.5A.5.5 0 0 1 3 0h10a.5.5 0 0 1 .5.5c0 .538-.012 1.05-.034 1.536a3 3 0 1 1-1.133 5.89c-.79 1.865-1.878 2.777-2.833 
                                 3.011v2.173l1.425.356c.194.048.377.135.537.255L13.3 15.1a.5.5 0 0 1-.3.9H3a.5.5 0 0 1-.3-.9l1.838-1.379c.16-.12.343-.207.537-.255L6.5 
                                 13.11v-2.173c-.955-.234-2.043-1.146-2.833-3.012a3 3 0 1 1-1.132-5.89A33.076 33.076 0 0 1 2.5.5zm.099 2.54a2 2 0 0 0 .72 
                                 3.935c-.333-1.05-.588-2.346-.72-3.935zm10.083 3.935a2 2 0 0 0 .72-3.935c-.133 1.59-.388 2.885-.72 3.935zM3.504 1c.007.517.026 1.006.056 
                                 1.469.13 2.028.457 3.546.87 4.667C5.294 9.48 6.484 10 7 10a.5.5 0 0 1 .5.5v2.61a1 1 0 0 1-.757.97l-1.426.356a.5.5 0 0 0-.179.085L4.5 
                                 15h7l-.638-.479a.501.501 0 0 0-.18-.085l-1.425-.356a1 1 0 0 1-.757-.97V10.5A.5.5 0 0 1 9 10c.516 0 1.706-.52 
                                 2.57-2.864.413-1.12.74-2.64.87-4.667.03-.463.049-.952.056-1.469H3.504z"
                            />
                        </svg>
                    </b-button>
                </b-col>
            </b-row>

            <b-row>
                <b-col sm="12" md="4"><b>Taken: </b>{{ displayDate }}</b-col>
                <b-col sm="12" md="4"><b>Distance: </b>{{ distance }} mi</b-col>
                <b-col sm="12" md="4"><b>Calories: </b>{{ calories }} kCal</b-col>
                <b-col sm="12" md="4"><b>Avg Output: </b>{{ avgOutput }} Watts</b-col>
                <b-col sm="12" md="4"><b>Peak Output: </b>{{ maxOutput }} Watts</b-col>
                <b-col sm="12" md="4"><b>Total Output: </b>{{ totalOutput }} kJ</b-col>
            </b-row>

            <b-row>
                <b-col cols="12" class="py-2">
                    <label for="ride-rating">How did it feel?</label>
                    <b-form-rating id="ride-rating" inline v-model="modifiedRating" show-clear></b-form-rating>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12">
                    <b-form-group class="mb-0" label-for="notes" :description="noteCharsRemaining">
                        <b-form-textarea
                            id="notes"
                            v-model="modifiedNote"
                            placeholder="Save ride notes..."
                            rows="1"
                            max-rows="6"
                            maxLength="256"
                        ></b-form-textarea>
                    </b-form-group>
                </b-col>
            </b-row>
            <b-row>
                <b-col>
                    <b-button v-if="saveAllowed" variant="primary" v-on:click="saveRide" class="float-right">Save </b-button>
                </b-col>
            </b-row>
        </b-container>
    </DashboardWidget>
</template>

<script>
import _ from "lodash";
import axios from "axios";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
    name: "Ride",
    components: { DashboardWidget },
    props: { ride: Object },
    data() {
        return {
            maxNoteSize: 256,
            rating: 0,
            modifiedNote: this.ride.note ? this.ride.note : "",
            modifiedRating: this.ride.rating,
            saveAllowed: false,
        };
    },
    watch: {
        modifiedNote: function () {
            this.saveAllowed = this.modifiedNote !== this.ride.note;
        },
        modifiedRating: function () {
            this.saveAllowed = this.modifiedRating !== this.ride.rating;
        },
    },
    computed: {
        ftp() {
            return this.$store.state.userData.cycling_workout_ftp;
        },
        noteCharsRemaining() {
            return this.modifiedNote.length + "/" + this.maxNoteSize;
        },
        title() {
            return this.ride.title;
        },
        distance() {
            return this.ride.distance;
        },
        calories() {
            return this.ride.calories;
        },
        totalOutput() {
            return this.ride.total_output;
        },
        avgOutput() {
            return this.ride.avg_output;
        },
        maxOutput() {
            return this.ride.max_output;
        },
        displayDate() {
            var rideDate = new Date(this.ride.created_at * 1000);
            var hour = rideDate.getHours() > 12 ? rideDate.getHours() - 12 : rideDate.getHours();
            var min = rideDate.getMinutes() < 10 ? "0" + rideDate.getMinutes() : rideDate.getMinutes();
            var amPm = rideDate.getHours() > 12 ? "PM" : "AM";

            return rideDate.getMonth() + 1 + "/" + rideDate.getDate() + "/" + rideDate.getFullYear() + " " + hour + ":" + min + " " + amPm;
            // return new Date(this.ride.created_at * 1000).toString();
        },
    },
    methods: {
        saveRide() {
            if (this.modifiedNote !== this.ride.note) {
                this.$store.commit("saveRideNote", { rideId: this.ride.id, value: this.modifiedNote });
            }

            if (this.modifiedRating !== this.ride.rating) {
                this.$store.commit("saveRideRating", { rideId: this.ride.id, value: this.modifiedRating });
            }

            this.$store.dispatch("saveAllRideData");
            this.saveAllowed = false;
        },
        // https://medium.com/critical-powers/formulas-from-training-and-racing-with-a-power-meter-2a295c661b46
        // https://purelyfunctional.tv/article/moving-average/
        calculateIntesityFactor() {
            const sessionId = localStorage.getItem("sessionId");

            if (!sessionId) {
                this.$router.push("login");
            } else {
                axios
                    .get("/api/workout/" + this.ride.id + "/performance_graph", {
                        params: {
                            every_n: 1,
                        },
                        headers: {
                            peloton_session_id: sessionId,
                        },
                    })
                    .then((response) => {
                        var outputMetrics = _.find(response.data.metrics, {
                            display_name: "Output",
                        }).values;

                        console.log(this.intensityFactor(outputMetrics));
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            }
        },
        sum(numbers) {
            return _.reduce(numbers, (a, b) => a + b, 0);
        },
        average(numbers) {
            return this.sum(numbers) / (numbers.length || 1);
        },
        window(_number, index, array) {
            const start = Math.max(0, index - 15);
            const end = Math.min(array.length, index + 15);
            return _.slice(array, start, end);
        },
        moving_average(numbers) {
            return _.chain(numbers).map(this.window).map(this.average).value();
        },
        toTheFourth(numbers) {
            return _.map(numbers, (val) => {
                return Math.pow(val, 4);
            });
        },
        intensityFactor(numbers) {
            return Math.pow(this.average(this.toTheFourth(this.moving_average(numbers))), 0.25) / this.ftp;
        },
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
label {
    margin-right: 10px;
}
span {
    font-weight: 100;
    font-size: 20px;
    width: 100%;
}
.b-rating {
    max-width: 300px;
    margin-left: auto;
    margin-right: auto;
}

.form-text {
    text-align: left;
}
</style>
