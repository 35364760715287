<template>
    <b-container class="soon"> Coming soon... </b-container>
</template>

<script>
export default {
    name: "Challenges",
    created() {
        window.scrollTo(0, 0);
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.soon {
    margin: 20px;
}
</style>
