<template>
    <div id="app">
        <TopNavBar />
        <router-view />
    </div>
</template>

<script>
// @ is an alias to /src
import TopNavBar from "@/components/TopNavBar.vue";

export default {
    name: "App",
    components: {
        TopNavBar,
    },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#app {
    background: #e4e7eb;
    min-height: 100vh;
}
</style>