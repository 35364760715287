<template>
  <DashboardWidget class="col-12" :title="title">
    <div class="toggle-container">
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="warmUpSwitch"
          v-model="includeWarmUps"
        />
        <label class="custom-control-label" for="warmUpSwitch">Warm Ups</label>
      </div>
      <div class="custom-control custom-switch">
        <input
          type="checkbox"
          class="custom-control-input"
          id="coolDownSwitch"
          v-model="includeCoolDowns"
        />
        <label class="custom-control-label" for="coolDownSwitch"
          >Cool Downs</label
        >
      </div>
    </div>

    <div class="chart-wrapper">
      <!-- For examples: https://www.highcharts.com/demo/stock -->
      <highcharts :constructorType="'stockChart'" :options="chartOptions">
      </highcharts>
    </div>
  </DashboardWidget>
</template>

<script>
import _ from "lodash";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "OutputOverTimeGraph",
  components: { DashboardWidget },
  methods: {
    convertRideToDataPoint(ride) {
      return [ride.created_at * 1000, ride.avg_output];
    },
    convertRideToFlag(ride, index) {
      var flag = {
        x: ride.created_at * 1000,
        val: ride.avg_output,
        title: index + 1,
      };

      return flag;
    },
  },
  watch: {
    includeWarmUps() {
      this.chartOptions.series[0].data = this.getFilteredData;
    },
    includeCoolDowns() {
      this.chartOptions.series[0].data = this.getFilteredData;
    },
  },
  computed: {
    rides() {
      return this.$store.state.rideData;
    },
    zones() {
      return this.$store.getters.zones;
    },
    sortedRides() {
      return _.sortBy(this.rides, ["created_at"]);
    },
    getFilteredData() {
      var filteredRides = this.sortedRides;

      if (!this.includeWarmUps) {
        filteredRides = _.filter(filteredRides, function(ride) {
          return !ride.title.includes("warm up");
        });
      }

      if (!this.includeCoolDowns) {
        filteredRides = _.filter(filteredRides, function(ride) {
          return !ride.title.includes("cool down");
        });
      }

      return _.map(filteredRides, this.convertRideToDataPoint);
    },
    getFtpTestFlags() {
      var ftpTests = _.filter(this.sortedRides, function(ride) {
        return ride.title.includes("ftp test");
      });

      return _.map(ftpTests, this.convertRideToFlag);
    },
  },
  created() {
    this.chartOptions.series[0].data = this.getFilteredData;
    this.chartOptions.series[1].data = this.getFtpTestFlags;
  },
  data() {
    return {
      title: "Ride Outputs",
      includeWarmUps: false,
      includeCoolDowns: false,
      chartOptions: {
        chart: {
          //height: 200
        },

        title: {
          text: "",
        },
        legend: {
          enabled: false,
        },
        rangeSelector: {
          inputEnabled: false,
        },
        xAxis: {
          type: "datetime",
          // dateTimeLabelFormats: {
          //     //https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
          //     millisecond: "%b %e",
          // },
          labels: {
            //https://api.highcharts.com/highcharts/xAxis.dateTimeLabelFormats
            format: "{value: %b '%y}",
            // align: "right",
            // rotation: -30,
          },
        },
        yAxis: {
          title: {
            text: '<span class="hc-cat-title">Avg Output</span><br/>(watts)',
          },
        },
        series: [
          {
            data: [], // can't evaluate until we compute a sorted list,
            id: "dataseries",
            tooltip: {
              pointFormat: "Avg Output: {point.y}",
            },
          },
          {
            //https://www.highcharts.com/demo/stock/flags-general
            type: "flags",
            data: [], // can't evaluate until we compute a sorted list,
            // onSeries: "dataseries",
            shape: "circlepin",
            tooltip: {
              pointFormatter: function() {
                return "New FTP: " + _.round(0.95 * this.val);
              },
            },
          },
        ],
        plotOptions: {
          series: {
            zones: [
              {
                value: this.$store.getters.zones[0],
                color: "#60aed2",
                className: "Zone 1",
              },
              {
                value: this.$store.getters.zones[1],
                color: "#51c7a9",
                className: "Zone 2",
              },
              {
                value: this.$store.getters.zones[2],
                color: "#b7ca58",
                className: "Zone 3",
              },
              {
                value: this.$store.getters.zones[3],
                color: "#dcb23c",
                className: "Zone 4",
              },
              {
                value: this.$store.getters.zones[4],
                color: "#d79a34",
                className: "Zone 5",
              },
              {
                value: this.$store.getters.zones[5],
                color: "#da7214",
                className: "Zone 6",
              },
              {
                value: this.$store.getters.zones[6],
                color: "#df4155",
                className: "Zone 7",
              },
            ],
          },
        },
      },
    };
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
h1 {
  text-align: center;
  margin-top: 20px;
  font-weight: 200;
}

.toggle-container {
  display: inline-flex;
  width: 100%;
  padding: 13px;
  justify-content: center;

  .custom-switch:not(:first-child) {
    margin-left: 10px;
  }
}

.chart-wrapper {
  width: 100%;
  padding: 5px;
}
</style>
