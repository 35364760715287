<template>
  <DashboardWidget class="col-12" :title="title">
    <div class="total-output">
      {{ userData.cycling_workout_ftp }}
    </div>
    <div class="days-since">({{ daysSinceTest }} days since last test)</div>
  </DashboardWidget>
</template>

<script>
import _ from "lodash";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
  name: "LastFtpTest",
  components: { DashboardWidget },
  data() {
    return {
      title: "Current FTP (avg watts)",
    };
  },
  computed: {
    userData() {
      return this.$store.state.userData;
    },
    rideData() {
      return this.$store.state.rideData;
    },
    ftpTests() {
      return _.filter(this.rideData, "is_ftp");
    },
    mostRecent() {
      var mostRecetTest = _.orderBy(this.ftpTests, ["created_at"], "desc")[0];
      return mostRecetTest;
    },
    daysSinceTest() {
      if (!this.mostRecent) {
        return 0;
      }

      var diffInSeconds =
        _.round(Date.now() / 1000) - this.mostRecent.created_at;

      return _.round(diffInSeconds / (3600 * 24));
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.total-output {
  font-weight: 900;
  font-size: 70px;
  text-align: center;
  width: 100%;
}
</style>
