import Vue from "vue";
import axios from "axios";
import VueRouter from "vue-router";
import GettingStarted from "@/views/GettingStarted.vue";
import Stats from "@/views/Stats.vue";
import Login from "@/views/Login.vue";
import About from "@/views/About.vue";
import Zones from "@/views/Zones.vue";
import FTP from "@/views/FTP.vue";
import Rides from "@/views/Rides.vue";
import Challenges from "@/views/Challenges.vue";
import store from "@/store/store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/login",
    name: "Login",
    component: Login,
  },
  {
    path: "/getting-started",
    name: "GettingStarted",
    component: GettingStarted,
  },
  {
    path: "/",
    name: "Stats",
    component: Stats,
  },
  {
    path: "/zones",
    name: "Zones",
    component: Zones,
  },
  {
    path: "/ftp",
    name: "FTP",
    component: FTP,
  },
  {
    path: "/rides",
    name: "Rides",
    component: Rides,
  },
  {
    path: "/challenges",
    name: "Challenges",
    component: Challenges,
  },
  {
    path: "/about",
    name: "About",
    component: About,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {
  const sessionId = localStorage.getItem("sessionId");

  if (!sessionId) {
    if (to.matched.some((record) => record.name === "Login")) {
      next();
    } else {
      next("/login");
    }
  } else {
    axios
      .get("/auth/check_session", {
        headers: {
          peloton_session_id: sessionId,
        },
      })
      .then((response) => {
        if (response.data.is_valid) {
          store.commit("userData", response.data.user);
          store.dispatch("refreshRideData");
          next();
        } else {
          localStorage.clear();
          next("/login");
        }
      })
      .catch((error) => {
        console.log(error);
        next("/login");
      });
  }
});

export default router;
