<template>
    <DashboardWidget class="col-12" :title="title">
        <div class="chart-wrapper">
            <highcharts :options="chartOptions"></highcharts>
        </div>
    </DashboardWidget>
</template>

<script>
import _ from "lodash";
import DashboardWidget from "@/components/DashboardWidget.vue";

export default {
    name: "PowerToWeightRatioGauge",
    props: { goal: Object },
    components: { DashboardWidget },
    computed: {
        gender() {
            return this.$store.state.userData.gender;
        },
        ftp() {
            return this.$store.state.userData.cycling_workout_ftp;
        },
        weight() {
            return this.$store.state.userData.weight;
        },
        target() {
            return _.round((this.goal.output * 0.95) / (this.goal.weight * this.lbsPerKg), 2);
        },
        chartOptions() {
            return {
                chart: {
                    inverted: true,
                    type: "bullet",
                    height: 200,
                },
                title: {
                    text: "",
                },
                legend: {
                    enabled: false,
                },
                xAxis: {
                    visible: false,
                    categories: ["Power-to-Weight Ratio"],
                },
                yAxis: {
                    max: 6.4,
                    min: 1.86,
                    gridLineWidth: 0,
                    plotBands:
                        this.gender.toLowerCase() === "male"
                            ? [
                                  {
                                      from: 0,
                                      to: 2.4,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.4,
                                      to: 2.58,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.58,
                                      to: 2.93,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.93,
                                      to: 3.11,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.11,
                                      to: 3.47,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.47,
                                      to: 3.64,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.64,
                                      to: 4.09,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.09,
                                      to: 4.18,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.18,
                                      to: 4.62,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.62,
                                      to: 4.8,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.8,
                                      to: 5.15,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.15,
                                      to: 5.33,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.33,
                                      to: 5.69,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.69,
                                      to: 5.87,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.87,
                                      to: 6.4,
                                      color: "#17a2b8",
                                  },
                              ]
                            : [
                                  {
                                      from: 0,
                                      to: 1.99,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 1.99,
                                      to: 2.16,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.16,
                                      to: 2.49,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.49,
                                      to: 2.65,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.65,
                                      to: 2.98,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 2.98,
                                      to: 3.14,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.14,
                                      to: 3.55,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.55,
                                      to: 3.64,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 3.64,
                                      to: 4.05,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.05,
                                      to: 4.21,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.21,
                                      to: 4.54,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.54,
                                      to: 4.7,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 4.7,
                                      to: 5.03,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.03,
                                      to: 5.2,
                                      color: "#17a2b8",
                                  },
                                  {
                                      from: 5.2,
                                      to: 5.69,
                                      color: "#17a2b8",
                                  },
                              ],

                    title: {
                        text: '<span class="hc-cat-title">Maximal Power Output</span><br/>(W/kg, 20 min)',
                    },
                },

                plotOptions: {
                    series: {
                        pointPadding: 0.2,
                        borderWidth: 0,
                        color: "#333",
                        targetOptions: {
                            width: "200%",
                        },
                    },
                },

                credits: {
                    enabled: false,
                },
                exporting: {
                    enabled: false,
                },
                series: [
                    {
                        data: [
                            {
                                y: _.round(this.ftp / (this.weight * this.lbsPerKg), 2),
                                target: this.target,
                            },
                        ],
                    },
                ],
                tooltip: {
                    pointFormat: "Current: {point.y}, Goal: {point.target}",
                },
            };
        },
    },
    data() {
        return {
            lbsPerKg: 0.45359237,
            title: "Power To Weight Ratio",
        };
    },
    // watch: {
    //     "goal.weight": function () {
    //         this.updateGoal();
    //     },
    //     "goal.output": function () {
    //         this.updateGoal();
    //     },
    //     "goal.date": function () {
    //         this.updateGoal();
    //     },
    // },
    // methods: {
    //     updateGoal() {
    //         this.chartOptions.series[0].data[0].target =
    //             this.goal.weight && this.goal.output ? _.round((this.goal.output * 0.95) / (this.goal.weight * this.lbsPerKg), 2) : 0;
    //     },
    // },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.chart-wrapper {
    padding: 5px;
    width: 100%;
}

h1 {
    text-align: center;
    margin-top: 20px;
    font-weight: 200;
}

.hc-cat-title {
    font-size: 13px;
    font-weight: bold;
}

.highcharts-figure,
.highcharts-data-table table {
    min-width: 320px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}
.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}
.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}
.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}
.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}
.highcharts-data-table tr:hover {
    background: #f1f7ff;
}
</style>
